import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Cookies from "universal-cookie"
import { Helmet } from "react-helmet"

import Layout from "../../components/SnickersNFLComponenets/components/layout"

import Agegate from "../../components/SnickersNFLComponenets/components/agegate"

const AgeGate = () => {
  let ageCookie = new Cookies()

  useEffect(() => {
    if (ageCookie.get("isOldEnough")) {
      navigate("/snickers-nfl/")
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        <link rel="stylesheet" href="../../SnickersNFL/css/app.css" />
        <link rel="stylesheet" href="../../SnickersNFL/css/responsive.css" />
      </Helmet>
      <Agegate />
    </Layout>
  )
}

export default React.memo(AgeGate)
